import "./App.css";
import { animations } from "react-animation";

import SEO from "./SEO";
import eagle from "./assets/icons/eagle.svg";
import logo7 from "./assets/icons/logo7.svg";
import logo from "./assets/crypto-logo.svg";
import logoX from "./assets/logo-x.svg";
import logo7footer from "./assets/logo-7.svg";
import carousel from "./assets/crypto-carousel.svg";

import telegram from "./assets/icons/telegram.svg";
import whatsapp from "./assets/icons/whatsapp.svg";
import instagram from "./assets/icons/instagram.svg";
import linkedin from "./assets/icons/linkedin.svg";

function App() {
    return (
        <>
            <SEO
                title="Crypto.al"
                description="Buy & Sell cryptocurrencies | Bitcoin, Etherium, XRP | Exchanche |"
                name="Crypto.a23"
                type="website"
                url="https://crypto.al/"
            />
            <div className="App">
                <p className="p-first">
                    One exchange <br />
                    for all your crypto{" "}
                </p>
                <div className="logo_container">
                    <div className="logo_inner">
                        <h1>Crypto</h1>
                        <div>
                            <img src={eagle} alt="eagle" />
                        </div>
                        <h1>al</h1>
                    </div>
                </div>
                <p className="p-second">
                    The portal is currently undergoing technical maintenance and
                    development.
                </p>

                <div className="progressbar">
                    <div className="progressbar-inner">
                        <p>Developing by Key7 SHPK</p>
                        <img src={logo7} alt="eagle" />
                    </div>
                </div>

                <p className="p-second p-third">
                    For collaboration, inquiries regarding the portal, or
                    participation in the project (as an investor), please use
                    the provided contact information:
                </p>
                <div className="socials-container">
                    <div className="social-block">
                        <div className="social-block-inner">
                            <a href="http://t.me//key7org"></a>
                            <img src={telegram} alt="social" />
                        </div>
                    </div>
                    <div className="social-block">
                        <div className="social-block-inner">
                            <a href="https://wa.me/355693068956"></a>
                            <img src={whatsapp} alt="social" />
                        </div>
                    </div>
                    <div className="social-block">
                        <div className="social-block-inner">
                            <a href="https://www.instagram.com/key7shpk"></a>
                            <img src={instagram} alt="social" />
                        </div>
                    </div>
                    <div className="social-block">
                        <div className="social-block-inner">
                            <a href="https://www.linkedin.com/company/key7org/"></a>
                            <img src={linkedin} alt="social" />
                        </div>
                    </div>
                </div>
                <div className="crypto-carousel-container">
                    <img
                        className="crypto-carousel"
                        src={carousel}
                        alt="crypto carousel"
                    />
                </div>
            </div>
            <div className="footer">
                <div className="footer-container">
                    <img src={logo} alt="crypto logo" />
                    <img src={logoX} alt="crypto X" />
                    <img src={logo7footer} alt="crypto logo" />
                </div>
            </div>
        </>
    );
}

export default App;
